<template>
    <div class="all" ref="all">
        <MPHeader1 :tab-index1="tabIndexs1" />
        <div class="bb">
            <img src="../../assets/20230905/index003.png" class="topimg">
        </div>
        <MPFooter04 />
    </div>
  </template>
  <script>
  
  import MPHeader1 from "@/components/header/MPHeader04.vue"; //头部
  import MPFooter04 from "@/components/footer/MPFooter04.vue"; //底部
  export default {
    components: {
        MPHeader1,
        MPFooter04
    },
    data() {
      return {
        tabIndexs1: 5, //用来传值给子组件,来显示点击了第几个
      };
    },
  };
  </script>


<style lang="less" scoped>
    .bb{
        width: 100%;
        height: 10rem;
        background-color: aqua;
        .topimg{
            width: 100%;
        }
    }
</style>