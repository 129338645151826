<template>
    <div>
      <div class="MP" v-if="isMP">
        <MPDealer />
      </div>
      <div class="PC" v-else>
        <PCDealer />
      </div>
    </div>
  </template>
  
  <script>
  import PCDealer from "@/components/dealer/PCDealer.vue";
  import MPDealer from "@/components/dealer/MPDealer.vue";
  export default {
    components: {
        PCDealer,
        MPDealer,
    },
    data() {
      return {
        isMP: true, //是否是手机
        windowWidth: document.documentElement.clientWidth, //屏幕宽度
      };
    },
    created() {
      // 根据屏幕宽度判断pc or mp
      const width = document.documentElement.clientWidth;
      if (width <= 450) {
        this.isMP = true;
      } else {
        this.isMP = false;
      }
    },
    mounted() {
      let that = this;
      window.onresize = function () {
        that.$store.state.screenWidth = document.documentElement.clientWidth; //窗口宽度
      };
    },
    watch: {
      // 监听窗口宽度
      "$store.state.screenWidth": function (val) {
        //监听屏幕宽度变化
        this.windowWidth = val;
        if (val <= 450) {
          this.isMP = true;
        } else {
          this.isMP = false;
        }
      },
    },
    methods: {
      // 联系子组件传来的值
      mpPhone(val) {
        if (val) {
          this.isMpPhone = true;
        } else {
          this.isMpPhone = false;
        }
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .MPPhone {
    z-index: 110;
  }
  </style>
  