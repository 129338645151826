<template>
    <div class="alls">
      <PCHeader1 :tab-index1="tabIndexs1" :changeClass1="changeClass1" />
      <div class="bodys">Dealar</div>
      <PCFooter />
    </div>
  </template>
  <script>
  // import $ from "jquery";
  import PCHeader1 from "@/components/header/PCHeader04.vue"; //头部
  import PCFooter from "@/components/footer/PCFoote01.vue"; //底部
  export default {
    components: {
      PCHeader1,
      PCFooter,
    },
    data() {
      return {
        tabIndexs1: 5, //用来传值给子组件,来显示点击了第几个
      };
    },
    created() {
      //   这个用来在不同分辨率等比缩放css样式
      // $(function () {
      //   var w = window.screen.width;
      //   var zoom = w / 1920;
      //   $("#app").css({
      //     zoom: zoom,
      //     "-moz-transform": "scale(" + zoom + ")",
      //     "-moz-transform-origin": "top left",
      //   });
      // });
    },
    methods: {},
  };
  </script>
  <style lang="less" scoped>
  .bodys{
    width: 100%;
    height: 1000px;
  }
  </style>